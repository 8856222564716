import axios from './request'
import {datainnerApi, sign} from "./api";


export var getDrvingPoints =(params) =>{
    return axios({
        url:`${datainnerApi}/driver/getPoints`,
        method:'POST',
        data:sign(params)
    })
}
export const getExpInfo =(params) =>{//快递单号
    return axios({
        url:`${datainnerApi}/express/getExpInfo`,
        method:'POST',
        data:sign(params)
    })
}
